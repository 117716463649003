import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as CALENDAR_API from '../api/calendarApi';
import * as ACTION from '../constants/calendarActionTypes'
import Utils from "../helpers/Utils";

function* calendarWatcher() {
    yield takeEvery(ACTION.FETCH_ACTIVITY_CALENDAR, fetchCalendarMiddleware);
    yield takeEvery(ACTION.FETCH_ACTIVITY_LIST, fetchActivityListMiddleware);
    yield takeEvery(ACTION.FETCH_TASK_COUNT, fetchTaskCountMiddleware);
    yield takeEvery(ACTION.SAVE_ACTIVITY_FOLLOWUP, saveActivityFollowUpFlow);
    yield takeEvery(ACTION.FETCH_USERS_REQUEST, fetchUsersMiddleware);
}

function* fetchCalendarMiddleware(action) {
    try {
        const response = yield call(CALENDAR_API.fetchActivityCalendar, action.payload);
        let responseData = response.data.data;
        let responseFullData = response.data.full_data.last_page ?? ""
        yield put({ type: ACTION.ACTIVITY_CALENDAR, payload: responseData });
        yield put({ type: ACTION.UPDATE_CALENDAR_TOTAL_PAGE, payload: responseFullData });

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchActivityListMiddleware(action) {
    try {

        const response = yield call(CALENDAR_API.fetchActivityList, action.payload);
        let responseData = response.data.data;

        yield put({ type: ACTION.ACTIVITY_LIST, payload: responseData });

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchTaskCountMiddleware(action) {
    try {
        const response = yield call(CALENDAR_API.fetchTaskCount, action.payload);
        let responseData = response.data.data;
        yield put({ type: ACTION.TASK_COUNT, payload: responseData });

    } catch (error) {
        Utils.handleException(error);
    }
}

function* saveActivityFollowUpFlow(action) {
    const { payload } = action;
    try {
        const response = yield call(CALENDAR_API.saveActivityFollowup, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({ 'status': false, 'message': 'Something went wrong! Try again.' });
        Utils.handleException(error);
    }
}

function* fetchUsersMiddleware() {
    try {
        const payload = {};
        const response = yield call(CALENDAR_API.getTeamUserList, payload);
        let data = response.data;
        yield put({ type: ACTION.FETCH_USERS_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: ACTION.FETCH_USERS_FAILURE, payload: error.message });
        Utils.handleException(error);
    }
}

export default function* calendarMiddleware() {
    yield all([
        calendarWatcher(),
    ])
}