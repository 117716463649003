import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchActivityCalendar,
  fetchTaskCount,
  fetchUsersRequest,
  resetCalendarActivitySelectedTask,
  storeCalendarListSelectedFilter,
  storeCalendarListSelectedPage,
  storeCalendarListSelectedPageSize,
  storeCalendarListSelectedTask,
  updateActiveTaskId,
  updateActivityActiveTab,
  updateFilterActiveTab,
  updateSelectedUserId,
} from "../../actions/calendarAction";
import * as ACTION from "../../constants/calendarActionTypes";
import {
  FILTER_COMPLETE,
  FILTER_NEXT_WEEK,
  FILTER_OVERDUE,
  FILTER_PLANNED,
  FILTER_THIS_WEEK,
  FILTER_TODAY,
  FILTER_TOMORROW,
  TASK_ALL,
  TASK_CALL,
  TASK_DEADLINE,
  TASK_EMAIL,
  TASK_MEETING,
  TASK_TASK,
} from "../../constants/calendarActionTypes";
import { CALENDAR_TAB } from "../../constants/CoreConstants";
import useDelayCallback from "../../hooks/useDelayCallback";
import TaskAppointmentModal from "../Activity/TaskAppointmentModal/TaskAppointmentModal";
import ActivityModal from "./ActivityModal";
import ClockIcon, {
  CallIcon,
  CompleteIcon,
  DeadlineIcon,
  EmailIcon,
  ListIcon,
  MeetingIcon,
  OverdueIcon,
  TaskIcon,
} from "./CalanderIcon";
import BasicSelect from "../Common/custom/BasicSelect";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../constants/PermissionConstant";
import Utils from "../../helpers/Utils";

const taskBtnClasses =
  "button_group_single_btn px-2 d-inline-block white d-flex align-items-center justify-content-center";
const filterTabClass =
  "button_group_single_btn text-dark-blue-65 white px-2 bg-light-blue d-flex align-items-center justify-content-center";
const useStyles = makeStyles({
  confirmButton: {
    backgroundColor: "#3e50f7",
    color: "#fff",
    display: "flex",
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
    gap: 7,
    "&:focus, &:hover": {
      backgroundColor: "#3e50f7",
    },
  },
  allOPtion: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "6px 12px",
    backgroundColor: "#ebf2fe",
    borderRadius: "5px",
    border: "none",
    padding: "10px 30px",
  },
});
const App = (props) => {
  const classes = useStyles();
  const { itemPerSize, currentPage, users } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const tab = params.get("tab");
  const [activeTab, setActiveTab] = useState(props.activeTabActivityCalendar);
  const [taskCount, setTaskCount] = useState(props.taskCount);
  const [activeTaskId, setActiveTaskId] = useState(props.activeTaskId);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [openTaskAppointmentListModal, setOpenTaskAppointmentListModal] =
    useState(false);
  const [slotInfo, setSlotInfo] = useState([]);
  const [filter, setFilter] = useState(props.activeFilterId);
  const taskRef = useRef(null);
  const isUserHasPermission = window.hasPermission(
    PERMISSION_MODULES.MANAGE_OTHER_USER_CALENDAR,
    PERMISSION_LEVELS.WRITE
  );
  const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === "INSURANCE";
  useEffect(() => {
    props.fetchUsers();
  }, []);

  const closeActivityModel = () => {
    setOpenActivityModal(false);
  };

  const openTaskAppointmentModal = (item) => {
    // setSlotInfo(item);
    closeTaskAppointmentListModal();
    window.openGlobalActivityModal({
      // contactDetails:this.props.people,
      editData: item,
      callBack: fetchTask,
      from: "calendar",
    });
    // setOpenActivityModal(true);
  };

  const closeTaskAppointmentListModal = () => {
    setOpenTaskAppointmentListModal(false);
  };

  const loadListData = () => {
    setOpenTaskAppointmentListModal(true);
  };
  const fetchPageData = (params = {}) => {
    let obj = {};
    obj.activeTab = props.activeTabActivityCalendar;

    if (taskRef.current == null) {
      obj.filter = props.activeFilterId;
      obj.task_id = activeTaskId;
    }

    obj.pageSize = itemPerSize;
    obj.currentPage = currentPage;

    obj.userId = props.selectedUserId;

    props.fetchActivityCalendar({
      ...params,
      ...obj,
    });

    props.fetchTaskCount({
      task_id: activeTaskId,
      activeTab: props.activeTabActivityCalendar,
      userId: props.selectedUserId,
    });
  };
  const fetchTask = () => {
    taskRef.current = "no_need_filter";
    closeActivityModel();
    fetchPageData(
      props.activeTabActivityCalendar === ACTION.CALENDAR_TAB_VIEW
        ? props.calendarDate
        : {}
    );
  };

  useDelayCallback(
    () => {
      fetchPageData(
        props.activeTabActivityCalendar === ACTION.CALENDAR_TAB_VIEW
          ? props.calendarDate
          : {}
      );
    },
    [
      activeTaskId,
      props.activeFilterId,
      props.calendarDate,
      currentPage,
      itemPerSize,
      props.activeTabActivityCalendar,
    ],
    500
  );

  useEffect(() => {
    updateActiveTaskId(activeTaskId);
  }, [activeTaskId]);

  useEffect(() => {
    setTaskCount(props.taskCount);
  }, [props.taskCount]);

  useEffect(() => {
    if (props.activeTabActivityCalendar == ACTION.CALENDAR_TAB_VIEW) {
      setFilter(FILTER_PLANNED);
    }
    props.updateActivityActiveTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    updateFilterActiveTab(filter);
  }, [props.activeFilterId]);

  useEffect(() => {
    taskRef.current = null;
  }, [props.activityCalendar]);

  useEffect(() => {
    taskRef.current = "no_need_filter";
    if (tab) {
      let tempTab = ACTION.CALENDAR_TAB_VIEW;
      if (tab === CALENDAR_TAB.LIST) {
        tempTab = ACTION.LIST_TAB_VIEW;
        removeQueryParam(["tab"]);
      } else if (tab === CALENDAR_TAB.CALENDAR) {
        tempTab = ACTION.CALENDAR_TAB_VIEW;
        removeQueryParam(["tab"]);
      }
      dispatch(updateActivityActiveTab(tempTab));
    }
  }, []);
  const openGlobalActivityModal = (e) => {
    e.preventDefault();
    window.openGlobalActivityModal({
      callBack: fetchTask,
      moreData: { userId: props.selectedUserId },
    });
  };

  const moveToFilter = (toFilter) => {
    props.storeCalendarListSelectedPage(1);
    props.storeCalendarListSelectedPageSize(20);
    props.storeCalendarListSelectedFilter(toFilter);
    props.resetCalendarActivitySelectedTask();
    // setActiveTab(ACTION.LIST_TAB_VIEW)
    // props.updateActivityActiveTab(ACTION.LIST_TAB_VIEW)
    // setFilter(toFilter);
  };

  const updateFilter = (filterTab) => {
    props.storeCalendarListSelectedFilter(filterTab);
    props.resetCalendarActivitySelectedTask();
  };

  const updateTask = (taskId) => {
    setActiveTaskId(taskId);
    props.storeCalendarListSelectedTask(taskId);
    props.resetCalendarActivitySelectedTask();
  };

  const removeQueryParam = (paramsToRemove) => {
    const searchParams = new URLSearchParams(location.search);

    paramsToRemove.forEach((param) => searchParams.delete(param));
    const newSearch = searchParams.toString();
    history.replace({
      pathname: location.pathname,
      search: newSearch,
    });
  };
  const handleSelectChange = (event) => {
    props.updateSelectedUserId(event.target.value);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center activity__top__header">
        <h4 className="m-0 py-2">Activity</h4>
        <div className="d-flex activity__header__buttons">
          <span
            className={classes.allOPtion}
            onClick={() => moveToFilter(FILTER_PLANNED)}
          >
            <ClockIcon />
            All
          </span>
          <span
            onClick={() => moveToFilter(FILTER_TODAY)}
            className={classes.allOPtion}
          >
            <ClockIcon />
            Today : {taskCount.today !== undefined ? taskCount.today : 0}
          </span>
          <span
            onClick={() => moveToFilter(FILTER_OVERDUE)}
            className={classes.allOPtion}
          >
            <OverdueIcon />
            Overdue : {taskCount.overdue !== undefined ? taskCount.overdue : 0}
          </span>
          <span
            onClick={() => moveToFilter(FILTER_COMPLETE)}
            className={classes.allOPtion}
          >
            <CompleteIcon />
            Complete :{" "}
            {taskCount.complete !== undefined ? taskCount.complete : 0}
          </span>
        </div>
        {(isUserHasPermission && isAgencyTypeInsurance) && (
          <div style={{ width: "300px" }}>
            <BasicSelect
              options={users}
              value={props.selectedUserId || ''}
              onChange={handleSelectChange}
              mapping={{ label: "title", value: "id" }}
              defaultText="Filter by user"
              fullWidth
            />
          </div>
        )}
        <div className="activity_calendar_list_tab">
          <ul className="tabs d-inline-flex align-items-center">
            <li className="tab">
              <div
                style={{ backgroundColor: "#f6f7fb" }}
                className={
                  props.activeTabActivityCalendar === ACTION.LIST_TAB_VIEW
                    ? "accent--bg--color accent--text--color active_tab py-0 px-3 custom-cursor-pointer"
                    : "py-0 px-3 custom-cursor-pointer"
                }
                onClick={() =>
                  props.updateActivityActiveTab(ACTION.LIST_TAB_VIEW)
                }
              >
                List
              </div>
            </li>
            <li className="tab">
              <div
                style={{ backgroundColor: "#f6f7fb" }}
                className={
                  props.activeTabActivityCalendar === ACTION.CALENDAR_TAB_VIEW
                    ? "accent--bg--color accent--text--color active_tab py-0 px-3 custom-cursor-pointer"
                    : "py-0 px-3 custom-cursor-pointer"
                }
                onClick={() =>
                  props.updateActivityActiveTab(ACTION.CALENDAR_TAB_VIEW)
                }
              >
                Calendar
              </div>
            </li>
          </ul>
        </div>
        <button
          className={classes.confirmButton}
          onClick={(e) => openGlobalActivityModal(e)}
        >
          <AddIcon style={{ color: "white" }} />
          Add New Activity
        </button>

        {openActivityModal && (
          <ActivityModal
            openActivityModal={openActivityModal}
            setOpenActivityModal={() => closeActivityModel()}
            showAll={true}
            fetchTask={() => fetchTask()}
            slotInfo={slotInfo}
          />
        )}
      </div>

      <div className="activities_buttons_groups d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex py-2">
          <div className="button_group icon_buttons d-flex align-items-center radius-5 mt-2">
            <span
              title="Tasks list"
              onClick={() => loadListData()}
              data-target="task_appointment_modal"
              className="modal-trigger button_group_single_btn text-dark-blue-65 white px-2 bg-light-blue d-flex align-items-center justify-content-center"
            >
              <ListIcon />
            </span>
            <TaskAppointmentModal
              openTaskAppointmentModal={(item) =>
                openTaskAppointmentModal(item)
              }
              openTaskAppointmentListModal={openTaskAppointmentListModal}
              setOpenTaskAppointmentListModal={() =>
                closeTaskAppointmentListModal()
              }
              showAll={true}
              calendarDate={
                activeTab === ACTION.CALENDAR_TAB_VIEW ? props.calendarDate : {}
              }
            />
            <span
              title="All Tasks"
              onClick={() => updateTask(TASK_ALL)}
              className={
                activeTaskId == TASK_ALL
                  ? "active_task accent--bg--color " + taskBtnClasses
                  : taskBtnClasses
              }
            >
              ALL
            </span>
            <span
              title="Meeting"
              onClick={() => updateTask(TASK_MEETING)}
              className={
                activeTaskId == TASK_MEETING
                  ? "active_task accent--bg--color " + taskBtnClasses
                  : taskBtnClasses
              }
            >
              <MeetingIcon />
            </span>
            <span
              title="Call"
              onClick={() => updateTask(TASK_CALL)}
              className={
                activeTaskId == TASK_CALL
                  ? "active_task accent--bg--color " + taskBtnClasses
                  : taskBtnClasses
              }
            >
              <CallIcon />
            </span>
            <span
              title="Email"
              onClick={() => updateTask(TASK_EMAIL)}
              className={
                activeTaskId == TASK_EMAIL
                  ? "active_task accent--bg--color " + taskBtnClasses
                  : taskBtnClasses
              }
            >
              <EmailIcon />
            </span>
            <span
              title="Tasks"
              onClick={() => updateTask(TASK_TASK)}
              className={
                activeTaskId == TASK_TASK
                  ? "active_task accent--bg--color " + taskBtnClasses
                  : taskBtnClasses
              }
            >
              <TaskIcon />
            </span>
            <span
              title="Deadline"
              onClick={() => updateTask(TASK_DEADLINE)}
              className={
                activeTaskId == TASK_DEADLINE
                  ? "active_task accent--bg--color " + taskBtnClasses
                  : taskBtnClasses
              }
            >
              <DeadlineIcon />
            </span>
          </div>
        </div>
        <div className="py-2 px-0 mt-2">
          {activeTab == ACTION.LIST_TAB_VIEW && (
            <div className="button_group d-flex align-items-center radius-5">
              <span
                onClick={() => updateFilter(FILTER_PLANNED)}
                className={
                  (props.activeFilterId == FILTER_PLANNED
                    ? "accent--bg--color " + filterTabClass
                    : filterTabClass) + ""
                }
              >
                Planned
              </span>
              <span
                onClick={() => updateFilter(FILTER_OVERDUE)}
                className={
                  (props.activeFilterId == FILTER_OVERDUE
                    ? "accent--bg--color " + filterTabClass
                    : filterTabClass) + ""
                }
              >
                Overdue
              </span>
              <span
                onClick={() => updateFilter(FILTER_TODAY)}
                className={
                  (props.activeFilterId == FILTER_TODAY
                    ? "accent--bg--color  " + filterTabClass
                    : filterTabClass) + ""
                }
              >
                Today
              </span>
              <span
                onClick={() => updateFilter(FILTER_TOMORROW)}
                className={
                  (props.activeFilterId == FILTER_TOMORROW
                    ? "accent--bg--color  " + filterTabClass
                    : filterTabClass) + ""
                }
              >
                Tomorrow
              </span>
              <span
                onClick={() => updateFilter(FILTER_THIS_WEEK)}
                className={
                  (props.activeFilterId == FILTER_THIS_WEEK
                    ? "accent--bg--color  " + filterTabClass
                    : filterTabClass) + ""
                }
              >
                This Week
              </span>
              <span
                onClick={() => updateFilter(FILTER_NEXT_WEEK)}
                className={
                  (props.activeFilterId == FILTER_NEXT_WEEK
                    ? "accent--bg--color  " + filterTabClass
                    : filterTabClass) + ""
                }
              >
                Next Week
              </span>
              <span
                onClick={() => updateFilter(FILTER_COMPLETE)}
                className={
                  (props.activeFilterId == ACTION.FILTER_COMPLETE
                    ? "accent--bg--color  " + filterTabClass
                    : filterTabClass) + ""
                }
              >
                Completed
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activeTabActivityCalendar: state.calendarReducer.activeTabActivityCalendar,
    activityCalendar: state.calendarReducer.activityCalendar,
    taskCount: state.calendarReducer.taskCount,
    activeTaskId: state.calendarReducer.activeTaskId,
    activeFilterId: state.calendarReducer.activeFilterId,
    selectedUserId: state.calendarReducer.selectedUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivityCalendar: (params) => dispatch(fetchActivityCalendar(params)),
    fetchTaskCount: (params) => dispatch(fetchTaskCount(params)),
    updateActivityActiveTab: (params) =>
      dispatch(updateActivityActiveTab(params)),
    updateActiveTaskId: (params) => dispatch(updateActiveTaskId(params)),
    updateFilterActiveTab: (params) => dispatch(updateFilterActiveTab(params)),
    storeCalendarListSelectedPageSize: (params) =>
      dispatch(storeCalendarListSelectedPageSize(params)),
    storeCalendarListSelectedPage: (params) =>
      dispatch(storeCalendarListSelectedPage(params)),
    storeCalendarListSelectedFilter: (params) =>
      dispatch(storeCalendarListSelectedFilter(params)),
    storeCalendarListSelectedTask: (params) =>
      dispatch(storeCalendarListSelectedTask(params)),
    resetCalendarActivitySelectedTask: () =>
      dispatch(resetCalendarActivitySelectedTask()),
    updateSelectedUserId: (params) => dispatch(updateSelectedUserId(params)),
    fetchUsers: () => dispatch(fetchUsersRequest()),
  };
};

const TopPart = connect(mapStateToProps, mapDispatchToProps)(App);

export default TopPart;