import React, {useEffect, useState} from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {connect} from "react-redux";
import If from "if-else-react";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


const App = (props) => {
    const classes = useStyles();

    const [date_range_type, setDate_range_type] = useState(0);
    const [rolling_days, setRolling_days] = useState(1);
    const [start_date, setStart_date] = useState(0);
    const [end_date, setEnd_date] = useState(0);
    const [old_end_date, setOld_end_date] = useState(0);
    const [focusedInput, setFocusedInput] = useState(true);
    const [dayInputError, setDayInputError] = useState(false);

    const onDateChange = (startDate, endDate) => {
        setOld_end_date(end_date);
        setStart_date(startDate);
        setEnd_date(endDate);
    }

    const OverAPeriodOfRollingDays=1;
    const DateRange=2;
    const Indefinitely=3;

    const generateEventRange = (event) => {
        event.preventDefault();
        setDayInputError(false);

        var oldEndDate = end_date;

        if(date_range_type==OverAPeriodOfRollingDays){
            if(rolling_days == '' || rolling_days == 0){
                setDayInputError(true);
                return false;
            }

            let currentDate = moment().format('YYYY-MM-DD');

            setStart_date(currentDate);
            var newEndDate = moment(currentDate, 'YYYY-MM-DD').add(rolling_days-1, 'days').format('YYYY-MM-DD');
            setEnd_date(newEndDate);

            props.generateEventRange(currentDate, newEndDate, moment(oldEndDate).format('YYYY-MM-DD'), rolling_days);
        }else if(date_range_type == DateRange){

            let currentDate = moment(start_date).format('YYYY-MM-DD');
            let lastDate = moment(end_date).format('YYYY-MM-DD');

            setStart_date(currentDate);

            props.generateEventRange(currentDate, lastDate, moment(oldEndDate).format('YYYY-MM-DD'), 0);
        }else{
            let currentDate = moment().format('YYYY-MM-DD');

            setStart_date(currentDate);

            var newEndDate = moment().add(365, 'days').format('YYYY-MM-DD');
            setEnd_date(newEndDate);
            props.generateEventRange(currentDate, newEndDate, moment(oldEndDate).format('YYYY-MM-DD'), 0);
        }




    }

    const setRollingDays = (event) => {
        setDayInputError(false);

        if (parseInt(event.target.value) <= 0 || parseInt(event.target.value) > 120)
        {
            setDayInputError(true);
            event.preventDefault();
        }

        setRolling_days(event.target.value);
    }

    useEffect(()=>{
        if(props.calendarDetails.id != undefined){
            if(props.calendarDetails.rolling_days > 0){
                setDate_range_type(OverAPeriodOfRollingDays);
            }else{
                setDate_range_type(DateRange);
            }

            setRolling_days(props.calendarDetails.rolling_days);
            setStart_date(props.calendarDetails.start_date);
            setEnd_date(props.calendarDetails.end_date);
        }
    }, [props.calendarDetails])


    useEffect(()=>{
        if(date_range_type != OverAPeriodOfRollingDays)
            setDayInputError(true);
    }, [date_range_type])


    return (
        <React.Fragment>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.isOpen}
                onClose={props.hideModal}
                closeAfterTranstion
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isOpen}>
                    <div id="create_calendar" className="calendar_widget_time_interval_modal modal d-block">
                        <div className="modal-content">
                            <div className="header">
                                <h5>Availability</h5>
                            </div>
                            <div className="modal_article tab-availability-modal-content">
                                <div className="row">
                                    <div className="col s12">
                                        <div className="form-group">
                                            <label className="m--font-bolder ">When can appointments be scheduled</label>
                                            <select className="form-control mt-2 mb-2 p-5-10" name="date_range_type" value={date_range_type} onChange={(event)=>setDate_range_type(event.target.value)}>
                                                <option value={0}> Select Option </option>
                                                <option value={OverAPeriodOfRollingDays}> Over a period of rolling days</option>
                                                <option value={DateRange}> Date range</option>
                                                <option value={Indefinitely}> Indefinitely (1 year)</option>
                                            </select>
                                        </div>
                                    </div>

                                    <If condition={date_range_type == OverAPeriodOfRollingDays}>
                                        <div className="col s12">
                                            <div className="form-group">
                                                <p ><span> Your invitees will be offered availability indefinitely into the future..</span></p>
                                                <input onChange={ (event)=>setRollingDays(event)} defaultValue={rolling_days} placeholder="Days"  type="number" min={0} className="form-control permalink" />
                                                <span className="ml-2">days</span>
                                            </div>
                                            {dayInputError && <p className={"text-red"}>Day field is required</p>}
                                        </div>
                                    </If>

                                    <If condition={date_range_type == DateRange}>
                                        <div className="col s12">
                                            <div className="form-group">
                                                <p><span> Your invitees will be offered availability for a number of days into the future.</span></p>
                                                <DateRangePicker
                                                    small = {true}
                                                    startDate = {moment(start_date)} // momentPropTypes.momentObj or null,
                                                    startDateId = "your_unique_start_date_id" // PropTypes.string.isRequired,
                                                    endDate = {moment(end_date)} // momentPropTypes.momentObj or null,
                                                    endDateId = "your_unique_end_date_id" // PropTypes.string.isRequired,
                                                    onDatesChange = {({ startDate, endDate }) => onDateChange(startDate, endDate)} // PropTypes.func.isRequired,
                                                    focusedInput = {focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                    onFocusChange = {focusedInput => setFocusedInput(focusedInput )} // PropTypes.func.isRequired,
                                                />
                                            </div>
                                        </div>
                                    </If>

                                    <If condition={date_range_type == Indefinitely}>
                                        <div className="col s12">
                                            <div className="form-group">
                                                <p><span> Your invitees will be offered availability indefinitely into the future..</span></p>
                                            </div>
                                        </div>
                                    </If>

                                </div>
                            </div>
                            <div className="massage_send_close_button massage_send_close_button_daterange d-flex justify-content-center align-items-center">
                                <button type="button" onClick={(event)=>generateEventRange(event)} href="javascript:void(0)" className="btn btn_info apply-btn mr-2 alt accent--bg--color">
                                                    <span className="mr-2">
                                                        <AddIcon/>
                                                    </span>Apply
                                </button>
                                <a onClick={()=>props.hideModal()} href="javascript:void(0)" className="close_btn alt">
                                            <span>
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="white"></circle><path d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z" fill="#FF264A"></path></svg>
                                            </span> Close
                                </a>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );

}

const mapStateToProps = state => {
    return {
        calendarDetails : state.calendarWidgetReducer.calendarWidgetDetails,
    };
};

const DateRangeModal = connect(mapStateToProps, null)(App);

export default DateRangeModal;
