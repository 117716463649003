export const PERMISSION_MODULES = {
    MANAGE_BILLING: 0,
    MANAGE_USERS: 1,
    MANAGE_GLOBAL_ITEMS: 2,
    EXPORT_CONTACTS: 3,
    EDIT_LEAD_OWNER: 4,
    MANAGE_OTHER_USER_GOALS: 5,
    MANAGE_OTHER_USER_REPORTS: 6,
    MANAGE_OTHER_USER_CALENDAR: 7,
    MANAGE_OTHER_USER_SCHEDULER: 8,
};

export const PERMISSION_LEVELS = {
    READ: 2,
    WRITE: 4,
    DELETE: 8,
};