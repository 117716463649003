import { markAsDone, setDefaultCalendar, turnOnCalendarWidget } from "../api/calendarApi";

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar`
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`

const Api = {
    getActivityCalendar: `${rootUrl}/get-activity-list-for-calendar`,
    getActivityList: `${rootUrl}/get-activity-list`,
    getTaskCount: `${rootUrl}/get-task-count`,
    getTeamUsers: `${rootUrl}/get-team-users`,
    getContacts: `${rootUrl}/search-contacts`,
    saveActivityFollowup: `${rootUrl}/save-activity`,
    markAsDone: `${rootUrl}/activity-mark-as-done`,
    getCalendarWidgetList: `${rootUrl}/get-calendar-widget-list`,
    turnOnCalendarWidget: `${rootUrl}/turn-on-calendar-widget`,
    setDefaultCalendar: `${rootUrl}/make-calendar-default`,
    createNewCalendarWidget: `${rootUrl}/create-new-calendar-widget`,
    deleteCalendarWidget: `${rootUrl}/delete-calendar-widget`,
    fetchCalendarWidgetDetails: `${rootUrl}/fetch-calendar-widget-details`,
    fetchCampaignList: `${rootUrl}/fetch-campaign-list`,
    fetchLeadFlowList: `${rootUrl}/fetch-leadF-flow-list`,
    fetchCalendarWidgetScheduledDetails: `${rootUrl}/fetch-calendar-widget-schedule-details`,
    updateBasicSection: `${rootUrl}/update-calendar-widget-basic-section`,
    updateCalendarSection: `${rootUrl}/update-calendar-widget-calendar-section`,
    updateConfirmationSection: `${rootUrl}/update-calendar-widget-confirmation-section`,
    updatePolicySection: `${rootUrl}/update-calendar-widget-policy-section`,
    regenerateCalendarEvents: `${rootUrl}/regenerate-calendar-events`,
    updateCalendarWidgetStatus: `${rootUrl}/update-calendar-widget-status`,
    emailUploadFileApi: `${rootUrl}/upload-email-file`,

    fetchCalendarDetailsForPublicPage: `${rootUrl}/calendar-schedule/details`,
    scheduleBooking: `${rootUrl}/calendar-schedule-booking`,

    deleteActivityForCalendar: `${rootUrl}/delete-activity-for-calendar`,

    deleteBulkActivityForCalendar: `${rootUrl}/delete-bulk-activity-for-calendar`,
    userConnectedCalendarList: `${containerUrl}/user-connected-calendar-list`,

    teamUserList: `${tenantBackendV1}/users/team-user-list`,

}

export default Api;