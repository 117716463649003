import axios from "axios";
import {getCookie} from "../helpers/Cookie";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
        config.headers.Authorization = `Bearer ${apiKey}`;
        if (config.method === 'get' || config.method === 'delete') {
            config.params = {...config.params, "api-key": apiKey};
        } else if (config.method === 'post' || config.method === 'put') {
            config.data = {...config.data, "api-key": apiKey};
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log("Error: axios ", error.message);
        }
        return Promise.reject(error);
    }
);

const HttpRequest = {
    get: (url = "", params = {}) =>
        axiosInstance.get(url, {params}),

    post: (url = "", body = {}) =>
        axiosInstance.post(url, body),

    put: (url = "", body = {}) =>
        axiosInstance.put(url, body),

    delete: (url = "", params = {}) =>
        axiosInstance.delete(url, {params}),
};

export default HttpRequest;