import * as ACTION from "../constants/calendarActionTypes";
import React from "react";

const initialState = {
    activeTabActivityCalendar : ACTION.CALENDAR_TAB_VIEW,
    activeTaskId : ACTION.TASK_ALL,
    activeFilterId : ACTION.FILTER_PLANNED,
    activityCalendar : [],
    activityList : [],
    taskCount : [],
    teamUsers : [],
    selectedCalendarPage:1,
    calendarItemPerPage:20,
    calendarTotalPage:'',
    isButtonDisable:true,
    isLoading: false,
    calendarActivitySelectedTask: [],
    updateTask: false,
    selectedUserId: null
};

const marketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.ACTIVITY_CALENDAR:
            return {...state, activityCalendar: action.payload, updateTask: false, isLoading:false}

        case ACTION.ACTIVITY_LIST:
            return {...state, activityList: action.payload}

        case ACTION.TASK_COUNT:
            return {...state, taskCount: action.payload}

        case ACTION.UPDATE_VIEW_ACTIVE_TAB:
            return {...state, activeTabActivityCalendar: action.payload ,isLoading: true}

        case ACTION.UPDATE_TASK_ACTIVE_TAB:
            return {...state, activeTaskId: action.payload}
        case ACTION.UPDATE_CALENDAR_PAGE_SIZE:
            return {...state, calendarItemPerPage: action.payload}

        case ACTION.UPDATE_CALENDAR_PAGE:
            return {...state, selectedCalendarPage: action.payload}

        case ACTION.UPDATE_CALENDAR_TOTAL_PAGE:
            return {...state, calendarTotalPage: action.payload}

        case ACTION.UPDATE_CALENDAR_ACTIVITY_TASK:
            return {...state, calendarTotalPage: action.payload,isLoading: true}

        case ACTION.UPDATE_TASK_UPDATE_FLAG:
            return {...state, updateTask: action.payload}

        case ACTION.UPDATE_CALENDAR_ACTIVITY_FILTER:
            return {...state, activeFilterId: action.payload ,isLoading: true}
    

        case ACTION.UPDATE_CALENDAR_ACTIVITY_LIST:
            return {...state, activityCalendar: action.payload}

        case ACTION.UPDATE_CALENDAR_ACTIVITY_SELECTED_TASK:
            return {...state, calendarActivitySelectedTask: action.payload }

        case ACTION.RESET_CALENDAR_ACTIVITY_SELECTED_TASK:
            return {...state, calendarActivitySelectedTask: [] }

        // Add cases for fetching users
        case ACTION.FETCH_USERS_REQUEST:
            return { ...state, isLoading: true, error: null };

        case ACTION.FETCH_USERS_SUCCESS:
            return { ...state, teamUsers: action.payload, isLoading: false, error: null };

        case ACTION.FETCH_USERS_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case ACTION.UPDATE_SELECTED_USER_ID:
            return {...state, selectedUserId: action.payload };

        default:
            return state;
    }
}

export default marketingReducer;
