import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { connect } from "react-redux";
import { fetchActivityCalendar, fetchTaskCount, storeUpdateTask } from "../../actions/calendarAction";
import { TASK_ICON } from '../../constants/CoreConstants';
import './Calendar.css';

const localizer = momentLocalizer(moment);
let formats = {
    dayFormat: "ddd MM/DD",
    dayHeaderFormat: "ddd YYYY/MM/DD",
};

const calendarOptions = {
    formats
};

const App = (props) => {
    const [activityCalendar, setActivityCalendar] = useState(props.activityCalendar);
    const [activeTab, setActiveTab] = useState(props.activeTabActivityCalendar);
    const [openActivityModal, setOpenActivityModal] = useState(false);
    const [activeTaskId, setActiveTaskId] = useState(props.activeTaskId);

    useEffect(() => {
        if (props.selectedUserId) {
            fetchPageData();
        }
    }, [props.selectedUserId]);


    const closeActivityModel = () => {
        setOpenActivityModal(false);
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor = 'rgba(104, 235, 202, 0.4)';
        let color = '#133159';
        if (event.color !== undefined) {
            if (event.color !== '#ffeb3b' && event.color !== '#cddc39') {
                color = '#fff';
            }
            backgroundColor = event.color;
        }
        const style = {
            backgroundColor: backgroundColor,
            color: color
        };
        return {
            style: style
        };
    };

    const onSlotChange = (slotinfo) => {
        window.openGlobalActivityModal({
            callBack: fetchTask,
            slotInfo: slotinfo,
            moreData: { userId: props.selectedUserId }
        });
    };

    const onEventClick = (event) => {
        let contact = {};

        if (event.contactId !== undefined && event.contactId !== "" && event.contactId !== null) {
            event.contact_id = event.contactId;
        }

        if (event.contactNumber !== undefined && event.contactNumber !== "" && event.contactNumber !== null) {
            contact.number = event.contactNumber;
        }
        if (event.name !== undefined && event.name !== "" && event.name !== null) {
            contact.name = event.name;
        }
        if (event.email !== undefined && event.email !== "" && event.email !== null) {
            contact.email = event.email;
        }

        event.contact = contact;
        props.storeUpdateTask(true);
        window.openGlobalActivityModal({
            callBack: fetchTask,
            editData: event,
            from: 'calendar',
            moreData: { userId: props.selectedUserId }
        });
    };

    const fetchTask = () => {
        closeActivityModel();
        fetchPageData();
    };

    const fetchPageData = () => {
        props.fetchActivityCalendar({
            ...props.calendarDate,
            task_id: activeTaskId,
            activeTab: activeTab,
            userId: props.selectedUserId,
        });

        props.fetchTaskCount({
            task_id: activeTaskId,
            activeTab: activeTab,
            userId: props.selectedUserId,
        });
    };

    useEffect(() => {
        let dateObj = new Date();
        props.setCalendarDate({
            selectedYear: dateObj.getFullYear(),
            selectedMonth: dateObj.getMonth() + 1
        });
    }, []);

    useEffect(() => {
        let activities = props.activityCalendar;

        for (let i = 0; i < activities.length; i++) {
            let currentSlotStartTime = moment(activities[i]['start']).format('YYYY-MM-DD HH:mm:ss');

            let convertedTimeYear = parseInt(currentSlotStartTime.substring(0, 4));
            let convertedTimeMonth = parseInt(currentSlotStartTime.substring(5, 7)) - 1; // JavaScript accepts January = 0
            let convertedTimeDay = parseInt(currentSlotStartTime.substring(8, 10));
            let convertedTimeHour = parseInt(currentSlotStartTime.substring(11, 13));
            let convertedTimeMinute = parseInt(currentSlotStartTime.substring(14, 16));
            let convertedTimeSecond = parseInt(currentSlotStartTime.substring(17, 19));

            if (isNaN(convertedTimeSecond)) {
                convertedTimeSecond = 0;
            }
            activities[i]['customTitle'] = <><Icon>{TASK_ICON[activities[i]['taskTitle']]}</Icon> {activities[i]['title']}</>;
            activities[i]['start'] = new Date(Date.UTC(
                convertedTimeYear, convertedTimeMonth, convertedTimeDay, convertedTimeHour, convertedTimeMinute, convertedTimeSecond));
            activities[i]['end'] = new Date(Date.UTC(
                convertedTimeYear, convertedTimeMonth, convertedTimeDay, convertedTimeHour, convertedTimeMinute, convertedTimeSecond));

            activities[i]['end'].setHours(activities[i]['end'].getHours() + parseInt(activities[i]['taskDuration'].substring(0, 2)));
            activities[i]['end'].setMinutes(activities[i]['end'].getMinutes() + parseInt(activities[i]['taskDuration'].substring(3, 5)));
        }

        setActivityCalendar(activities);
    }, [props.activityCalendar.length, props.updateTaskBoolean]);

    const onNavigateChange = (date) => {
        props.setCalendarDate({
            selectedYear: date.getFullYear(),
            selectedMonth: date.getMonth() + 1
        });
    };

    return (
        <>
            <div className='active__calender__modal'>
                {
                    props.isLoading && <span className='activity__loader'><p>Please Wait ...</p></span>
                }
                {
                    !props.isLoading &&
                    <Calendar
                        {...calendarOptions}
                        step={60}
                        showMultiDayTimes
                        popup
                        defaultDate={new Date()}
                        selectable={true}
                        localizer={localizer}
                        events={activityCalendar}
                        titleAccessor="customTitle"
                        startAccessor="start"
                        endAccessor="end"
                        eventPropGetter={(eventStyleGetter)}
                        onSelectEvent={event => onEventClick(event)}
                        onSelectSlot={(slotinfo) => onSlotChange(slotinfo)}
                        onNavigate={(date) => onNavigateChange(date)}
                        dayLayoutAlgorithm="no-overlap"
                    />
                }
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        activityCalendar: state.calendarReducer.activityCalendar,
        activeTabActivityCalendar: state.calendarReducer.activeTabActivityCalendar,
        updateTaskBoolean: state.calendarReducer.updateTask,
        taskCount: state.calendarReducer.taskCount,
        isLoading: state.calendarReducer.isLoading,
        selectedUserId: state.calendarReducer.selectedUserId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchActivityCalendar: (params) => dispatch(fetchActivityCalendar(params)),
        fetchTaskCount: (params) => dispatch(fetchTaskCount(params)),
        storeUpdateTask: (params) => dispatch(storeUpdateTask(params)),
    };
};

const MyCalendar = connect(mapStateToProps, mapDispatchToProps)(App);

export default MyCalendar;