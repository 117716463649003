import { FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  fetchTaskCount,
  storeCalendarListSelectedPage,
  storeCalendarListSelectedPageSize
} from "../../actions/calendarAction";
import * as ACTION from "../../constants/calendarActionTypes";
import Utils from "../../helpers/Utils";
import ActivityList from "../Activity/ActivityList/ActivityList";
import "./../Activity/Activity.css";
import MyCalendar from "./Calendar";
import "./index.css";
import TopPart from "./TopPart";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const ActivityCalendar = (props) => {
  const { activeTabActivityCalendar } = useSelector(
    (state) => state.calendarReducer
  );
  const [calendarDate, setCalendarDate] = useState();
  const [pageNoSearch, setPageNoSearch] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    document.title = `Calendar | ${Utils.getAccountData("AuthUserAgencyName")}`;
    window.setActiveSidebar("calendar");
  }, []);

  let previous_page = null,
    next_page = null;
  if (props.selectedCalendarPage > 1) {
    previous_page = props.selectedCalendarPage - 1;
  }
  // eslint-disable-next-line eqeqeq
  if (props.selectedCalendarPage != props.calendarTotalPage) {
    next_page = props.selectedCalendarPage + 1;
  }

  useEffect(() => {
    props.storeCalendarListSelectedPage(1);
    props.storeCalendarListSelectedPageSize(20);
  }, [activeTabActivityCalendar]);

  const handlePaginationChange = (event, pageNo) => {
    event.preventDefault();
    if (pageNo != null && pageNo !== "") {
      props.storeCalendarListSelectedPage(pageNo);
      // fetchAllContacts('pageNo', pageNo)
    } else if (
      pageNo === "" &&
      event.target.value.trim() !== "" &&
      !Utils.checkLetterExistInText(event.target.value)
    ) {
      let value = event.target.value;
      if (value <= props.calendarTotalPage && value >= 1) {
        value = Math.floor(value);
        props.storeCalendarListSelectedPage(value);
        clearTimeout(pageNoSearch);
        let tablePageInput = setTimeout(() => {
          //fetchAllContacts('pageNo', value)
        }, 1500);

        setPageNoSearch(tablePageInput);
      }
    } else if (
      pageNo === "" &&
      (event.target.value.trim() === "" ||
        Utils.checkLetterExistInText(event.target.value))
    ) {
      props.storeCalendarListSelectedPage(event.target.value);
    }
  };

  const handlePageSizeChange = (event) => {
    props.storeCalendarListSelectedPage(1);
    let newPageSize = event.target.value;
    if (newPageSize !== props.calendarItemPerPage) {
      props.storeCalendarListSelectedPageSize(newPageSize);
      //fetchAllContacts('pageSize', newPageSize)
    }
  };

  return (
    <div className="mr-0 white p-3 pt-2 radius-10">
      <div>
        <TopPart
          calendarDate={calendarDate}
          itemPerSize={props.calendarItemPerPage}
          currentPage={props.selectedCalendarPage}
          users={props.teamUsers}
        />
        <div className="white">
          {activeTabActivityCalendar == ACTION.LIST_TAB_VIEW && (
            <div id="activity__list__tab" className="col s12">
              <ActivityList
                itemPerSize={props.calendarItemPerPage}
                currentPage={props.selectedCalendarPage}
              />
            </div>
          )}

          {activeTabActivityCalendar == ACTION.CALENDAR_TAB_VIEW && (
            <div
              id="calendar__tab"
              className="col s12 activity--calendar-wrapper"
            >
              <MyCalendar
                calendarDate={calendarDate}
                setCalendarDate={(date) => setCalendarDate(date)}
              />
            </div>
          )}

          {!props.isLoading &&
            props.activityCalendar.length > 0 &&
            activeTabActivityCalendar !== ACTION.CALENDAR_TAB_VIEW && (
              <div className="row">
                <div className="col l12">
                  <div className="pagenation-main-area">
                    <div className="pagenation-top">
                      <form action="">
                        <FormControl
                          variant="filled"
                          className={classes.formControl}
                        >
                          {/* <InputLabel id="demo-simple-select-filled-label">Size</InputLabel> */}
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={props.calendarItemPerPage}
                            onChange={handlePageSizeChange}
                            //   label="PageSize"
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                          </Select>
                        </FormControl>
                      </form>
                      <span className="contact-pagenat">Size</span>
                    </div>
                    <div className="pagenation-middle">
                      <ul className="pagination">
                        <li className="arrow-left">
                          <a
                            href="#!"
                            onClick={(event) =>
                              handlePaginationChange(event, previous_page)
                            }
                          >
                            <i
                              className="material-icons"
                              style={
                                previous_page == null
                                  ? {
                                      cursor: "not-allowed",
                                      backgroundColor: "#9daac1",
                                      borderColor: "#9daac1",
                                    }
                                  : {}
                              }
                            >
                              chevron_left
                            </i>
                          </a>
                        </li>
                        <li className="active">
                          <div className="pagination--text">
                            <input
                              className="contact-pagination-no"
                              type="text"
                              placeholder="Search Existing Calendar "
                              onChange={(event) =>
                                handlePaginationChange(event, "")
                              }
                              value={props.selectedCalendarPage}
                            />
                          </div>
                        </li>
                        <li className="waves-effect">
                          <div className="pagination--text">/</div>
                        </li>
                        <li className="waves-effect">
                          <div className="pagination--text">
                            {props.calendarTotalPage}
                          </div>
                        </li>
                        <li className="arrow-left waves-effect">
                          <a
                            href="#!"
                            onClick={(event) =>
                              handlePaginationChange(event, next_page)
                            }
                          >
                            <i
                              className="material-icons"
                              style={
                                next_page == null
                                  ? {
                                      cursor: "not-allowed",
                                      backgroundColor: "#9daac1",
                                      borderColor: "#9daac1",
                                    }
                                  : {}
                              }
                            >
                              chevron_right
                            </i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="pagenation-bottom"></div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    taskCount: state.calendarReducer.taskCount,
    selectedCalendarPage: state.calendarReducer.selectedCalendarPage,
    calendarItemPerPage: state.calendarReducer.calendarItemPerPage,
    calendarTotalPage: state.calendarReducer.calendarTotalPage,
    isButtonDisable: state.calendarReducer.isButtonDisable,
    activityCalendar: state.calendarReducer.activityCalendar,
    activityList: state.calendarReducer.activityList,
    isLoading: state.calendarReducer.isLoading,
    teamUsers: state.calendarReducer.teamUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTaskCount: (params) => dispatch(fetchTaskCount(params)),
    storeCalendarListSelectedPageSize: (params) =>
      dispatch(storeCalendarListSelectedPageSize(params)),
    storeCalendarListSelectedPage: (params) =>
      dispatch(storeCalendarListSelectedPage(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCalendar);